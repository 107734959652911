import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Service from '../components/Service';
import services from '../data/services';
import LinkButton from '../components/LinkButton';

import './ServicePage.css';

import Process from '../assets/service/Process.png';
import ServiceStep from '../components/ServiceStep';

// import process from "../data/process"

function ServicePage() {
  return (
    <>
      <Container>
        <section className="service-section1">
          <Row md={12} className="offer-title-wrapper">
            <div>
              {window.innerWidth > 1024 ? (
                <h5 style={{ fontFamily: "wfont3" }}>
                  OUR SERVICE, <br />WHAT WE OFFER
                </h5>
              ) : (<>
                <h5 style={{ fontFamily: "wfont3" }}>
                  OUR SERVICE, WHAT WE OFFER
                </h5>
              </>)}
              <p>
                Our company can help you with these.
              </p>
            </div>
          </Row>
          <div className='offer-wrapper'>
            {window.innerWidth > 1024 ? (
              <p>Agora is skilled at ..</p>
            ) : (<></>)}
            {services.map(service => (
              <Row key={service.id}>
                <Col>
                  <Service
                    key={service.id}
                    title={service.title}
                    content={service.content}
                  />
                </Col>
              </Row>
            ))}
          </div>
        </section>
        <Row className='mb-5'>
          <LinkButton text="Contact Us" url="/contact-us" />
        </Row>
      </Container>
      {window.innerWidth < 768 ? (
        <Image src={Process} fluid />) : (<></>)}
      <section className="service-section2">
      <Container>
          <Row >
            <div className='text-overlay' style={{marginTop: '2%'}}>
            <h4 className="top-text">
                Our Team's
              </h4>
              <h5 className="top-text">
                Working Process
              </h5>
            </div>
          </Row>
          <Row className="process-container">
            <ServiceStep />
          </Row>
          <Row className="mb-3">
            <LinkButton text="See our Projects" url="/project" />
          </Row>
          </Container>
        </section>
    </>
  );
}

export default ServicePage;