import KIM from '../assets/home/kim.png';
import Outtake from '../assets/home/outtake.png';
import Vibe from '../assets/home/vibe.png';

const clients_partners = [
    {
      imageSrc: KIM,
      quote: "It was my honor to work with this wonderful company! The preview video in my album results from his passion and deep research. I am very glad to introduce his enthusiasm to you guys. I know that he will do his best for his fantastic clients!",
      author: "Jiyun Kim",
      role: "Pianist"
    },
    {
      imageSrc: Outtake,
      quote: "Agora Canada demonstrates a meticulously planning and executing visual communication strategies. As a dedicated partner, we remain steadfast in our pursuit of optimal solutions, consistently progressing with an unwavering commitment to efficiency and systematization.",
      author: "Jusung Hyung",
      role: "Outtake"
    },
    {
        imageSrc: Vibe,
        quote: "I can't say enough good things about Agora production. With their strategic guidance and engaging content, we've witnessed a steady growth in our follower base, and our posts now receive much higher levels of interaction.",
        author: "Daniel Samuel Flowrence",
        role: "Spain Vibe Tour"
      },
  ];

  export default clients_partners;