import React from 'react';
import './SubjectButton.css';

function SubjectButton({ subjects, selectedSubjects, onSelect}) {
  const handleClick = (title) => {
    if (selectedSubjects.includes(title)) {
      onSelect(title);
    } else {
      onSelect(title);
    }
  };

  return (
    <>
      {subjects.map(subject => (
        <span
        key={subject.id}
        onClick={() => handleClick(subject.title)}
          className={`subject-button ${selectedSubjects.includes(subject.title) ? 'selected' : ''}`}
        >
        {subject.title}
        </span>
    ))}
    </>
  );
}

export default SubjectButton;