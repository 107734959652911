import React from 'react';
import { Carousel, Container, Image } from 'react-bootstrap';
import './ClientsPartnersCarousels.css';

const ClientsPartnersCarousels = ({ items }) => {
    return (
            <Carousel fade indicators={false}>
                {items.map(({ imageSrc, quote, author, role }, index) => (
                    <Carousel.Item key={index}>
                        <Container className="client-partners-carousel-container">
                                <div className="circle">
                                    <Image src={imageSrc} fluid />
                                </div>
                                <p>
                                    {quote}
                                    <br /><br />
                                    {author},
                                    <br />
                                    <span className="clients-partners-title">{role}</span>
                                </p>
                        </Container>
                    </Carousel.Item>
                ))}
            </Carousel>
    );
}
export default ClientsPartnersCarousels;