import React from 'react';
import Header from './Header';
import Footer from './Footer';
import './Layout.css';

function Layout({ children }) {
  return (
    <div className="Layout">
      <header>
        <Header />
      </header>
      <main className="content-wrapper">{children}</main>

      <Footer />
    </div>
  );
}

export default Layout;