import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

import './ContactUsPage.css';
import ContactForm from '../components/ContactForm';

import VisitUs from '../assets/contact/visit.png';
import Call from '../assets/contact/call.png';
import Email from '../assets/contact/email.png';

function ContactUsPage() {
  return (
    <>
      <Container>
        <section className="contact-section1">
          <Row>
            <div className="p-3">
              <h4>Get in touch with us,</h4>
              <div className="h5-wrapper">
                <h5>AGORA PRODUCTION</h5>
              </div>
            </div>
          </Row>
        </section>
      </Container>
      <section className="gray-wrapper">
        <Container>
        <div className="p-4">
          <h4>Let us give you</h4>
          <h5>Quick Support</h5>
          <p style={{ fontWeight: 'bold' }}>
            Get in touch with us when you need any help
          </p>
          <div className="support-wrapper pt-4">
            <Row>
              <Col xs={2}><p><Image src={VisitUs} fluid /></p></Col>
              <Col xs={3} style={{ fontWeight: 'bold' }}><p>Visit Us</p></Col>
              <Col xs={7} ><p>7673 6th St, Burnaby, BC, Canada, V3N 3M8</p></Col>
            </Row>
            <Row>
              <Col xs={2}><p><Image src={Call} fluid /></p></Col>
              <Col xs={3} style={{ fontWeight: 'bold' }}><p>Call Us</p></Col>
              <Col xs={7}><p>(778) 323-5976</p></Col>
            </Row>
            <Row>
              <Col xs={2}><p><Image src={Email} fluid /></p></Col>
              <Col xs={3} style={{ fontWeight: 'bold' }}><p>Email Us</p></Col>
              <Col xs={7} ><p>info@agoraproduction.ca</p></Col>
            </Row>
          </div>
        </div>
        </Container>
      </section>
      <Container>
      <section className="contact-section2 mt-md-0 mb-md-0">
          <Row >
            <ContactForm
              text="How can we help you?"
              bottom_text={<>
                Tell us about your vision: Which <b>challenges</b> are you facing?
                What are your <b>goals and expectations</b>?
                What would success look like and how much are you planning to spend to get there?
              </>}
              isTextarea="true"
              isContactUs="true" />
          </Row>
        </section>
      </Container>
    </>

  );
}

export default ContactUsPage;