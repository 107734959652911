import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { Accordion, Row, Col } from 'react-bootstrap';
import './CustomAccordion.css';
import { videoprojects } from '../data/videoprojects';

const renderVideoContent = (video, category, isPlaying, onPlayPause) => {
    if (category === "Horizontal Video") {
        return (
            <>
                <ReactPlayer
                    url={video.link}
                    width="100%"
                    height="300px"
                    playing={isPlaying}
                    controls
                />
                <h6 style={{ textAlign: 'right', marginTop: '0px' }}>
                    {video.title} / {video.author}
                </h6>
            </>
        );
    }

    return (
        <div className="vertical-content video-container">
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                <ReactPlayer
                    url={video.link}
                    width="100%"
                    height="100%"
                    playing={isPlaying}
                    controls={false} // 기본 컨트롤 비활성화
                />
                {!isPlaying && (
                    <div
                        className="custom-play-button"
                        onClick={onPlayPause}
                    >
                        &#9658;
                    </div>
                )}
            </div>
        </div>
    );
};

function CustomAccordion({ additional }) {
    const [playingVideo, setPlayingVideo] = useState(null);

    const handlePlayPause = (videoId) => {
        setPlayingVideo(playingVideo === videoId ? null : videoId);
    };

    return (
        <Accordion defaultActiveKey="0" flush>
            {additional.map((category, index) => (
                <Accordion.Item eventKey={index.toString()} key={index}>
                    <Accordion.Header>{category}</Accordion.Header>
                    <Accordion.Body>
                        <Row className={`video-wrapper ${category === "Horizontal Video" ? "horizontal" : "vertical"}`}>
                            {videoprojects
                                .filter(video => video.category === category)
                                .map((video, videoIndex) => (
                                    <Col
                                        xs={12}
                                        md={category === "Horizontal Video" ? 6 : 12}
                                        lg={category === "Horizontal Video" ? 6 : 4}
                                        key={videoIndex}
                                    >
                                        {renderVideoContent(video, category, playingVideo === video.id, () => handlePlayPause(video.id))}
                                    </Col>
                                ))}
                        </Row>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default CustomAccordion;
