import React from 'react';
import Video from '../components/Video';
import LinkButton from '../components/LinkButton';
import { Container, Row, Col, Image } from 'react-bootstrap';

// CSS
import './HomePage.css';

/////////////////////////////
// Section 1: Welcome to ////
/////////////////////////////
import Main1_Mobile from '../assets/home/Main1_Mobile.png';

/////////////////////////////
// Section 3: Our Services //
/////////////////////////////
import Branding_Marketing from '../assets/home/Branding_Marketing.png';
import Google_Business_Set_Up from '../assets/home/Google_Business_Set_Up.png';
import Photo_Videography from '../assets/home/Photo_Videography.png';
import SEO_Set_Up from '../assets/home/SEO_Set_Up.png';
import Web_App from '../assets/home/Web_App.png';
import News_Ads from '../assets/home/News_Ads.png';
import ServiceItem from '../components/ServiceItem';

import FlowText from '../components/FlowText';

/////////////////////////////
// Section 4: Hear from /////
/////////////////////////////
import '../components/ContactForm';
import clients_partners from '../data/clients_partners';
import ClientsPartnersItem from '../components/ClientsPartnersItem';

/////////////////////////////
// Section 5: Get in touch //
/////////////////////////////
import ContactForm from '../components/ContactForm';

function HomePage() {
  return (
    <>
      <div style={{textAlign: "center"}}>
        <Video />
      </div>
      <Container>
        <section className="section1">
          <Row>
            <Col md={7}>
              <div >
                <h4>Welcome to </h4>
                <h5>AGORA PRODUCTION</h5>
                <p>
                  Agora is a public open space used for assemblies and markets in ancient Greek.
                  Just like agora, we wanted to create an "open space" platform for all people.
                </p>
              </div>
              <div>
                {window.innerWidth < 768 && (
                  <Image src={Main1_Mobile} fluid />
                )}
              </div>
            </Col>
          </Row>
          <Row className="mb-0">
            {window.innerWidth >= 768 && (
              <div style={{ paddingLeft: '20%' }}><h4>AGORA AS OPEN SPACE</h4></div>
            )}
          </Row>
        </section>
      </Container>
      {window.innerWidth < 768 && (
        //   <Carousels
        //   title="Partners of"
        //   subtitle="AGORA"
        //   items={[
        //     [['West Pacific', 'Baek & Lee', 'SOODA'], ['outtake', 'MAPO', 'Cornerstone college']],
        //     [['GIGU', 'Byeol Bam', 'Chang\'s Taekwondo'], ['Two Horns', 'Bar None Nightclub', 'LET EAT GO']]
        //   ]}
        // />
        <div className="black-border partners-wrapper black-wrapper" style={{width: '100vw'}}>
          <Container>
            <Row style={{color: 'white'}}>
              <Col xs={4} className="partners-title">
                <h4>Partners of </h4>
                <h5>AGORA</h5>
              </Col>
              <Col xs={8}>
                <FlowText texts={["West Pacific", "Baek & Lee", "SOODA", "outtake", "MAPO",
                  "Cornerstone College", "GIGU", "Byeol Bam", "Chang's Taekwondo",
                  "Two Horns", "Bar None Nightclub", "LET EAT GO"]} />
              </Col>
            </Row>
          </Container>
        </div>
      )}
      {/* <VerticalLineWithCircles /> */}
      <section className="section3">
        <div className='black-wrapper'>
          <Container>
          <Row> 
            <Col md={4} xs={12} className="title">
              <h4>Our Services</h4>
            </Col>
            <Col md={8} xs={12}>
              <div>
                <p>
                  Discover our marketing company, delivering creative solutions to over 70 clients and partners. From digital marketing to brand development, our passionate team crafts tailored strategies for maximum impact and ROI.
                </p>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
        <div className="image-wrapper">
          <Row>
            <Row>
              <ServiceItem
                imageSrc={Branding_Marketing}
                title="Branding / Marketing"
                description="Branding defines identity, marketing shares to world"
              />
              <ServiceItem
                imageSrc={Google_Business_Set_Up}
                title="Google Business set up"
                description="Online presence with our Google Business set up"
              />
            </Row>
            <Row>
              <ServiceItem
                imageSrc={Photo_Videography}
                title="Photo / Videography"
                description="Expert photo/videography services for your marketing"
              />
              <ServiceItem
                imageSrc={SEO_Set_Up}
                title="SEO set up"
                description="Boost online visibility with our professional SEO set up"
              />
            </Row>
            <Row>
              <ServiceItem
                imageSrc={Web_App}
                title="Web / App Design"
                description="Transform your vision into reality with our design"
              />
              <ServiceItem
                imageSrc={News_Ads}
                title="News / Ads"
                description="Stay informed with out compelling news and ads"
              />
            </Row>
          </Row>
          <Row className="clients-partners-container-wrapper">
            <Row className="clients-partners-wrapper">
              <Col className="clients-wrapper">
                <div>
                  <p className="number">+70</p>
                </div>
                <div>
                  <p>Clients</p>
                </div>
              </Col>
              <Col className="partners-wrapper">
                <div>
                  <p className="number">+20</p>
                </div>
                <div>
                  <p>Partners</p>
                </div>
              </Col>
            </Row>
          </Row>
        </div>
        <Container>
        <Row>
          {window.innerWidth < 768 ? (
            <LinkButton text="Find More" url="/service" />
          ) : (
            <LinkButton text="Find more about service" url="/service" />
          )
          }
        </Row>
        </Container>
      
      </section>
      {window.innerWidth >= 768 && (
        <>
          <div className="black-border partners-wrapper">
            <Container>
              <Row>
                <Col md={3} className="partners-title">
                  <h4>Partners of </h4>
                  <h5>AGORA</h5>
                </Col>
                <Col md={9}>
                  <FlowText texts={["West Pacific", "Baek & Lee", "SOODA", "outtake", "MAPO",
                    "Cornerstone College", "GIGU", "Byeol Bam", "Chang's Taekwondo",
                    "Two Horns", "Bar None Nightclub", "LET EAT GO"]} />
                </Col>
              </Row>
            </Container>
          </div>
        </>
      )}
      <section className="section4">
        <Container>
          <Row className="hear-from-wrapper">
            <Col xl={3} md={12}>
              <h4>
                Hear from
              </h4>
              <h5>
                AGORA'S CLIENT & PARTNERS
              </h5>
            </Col>
            {window.innerWidth >= 768 && (
              <>
                <Col md={12}>
                  <p>Join Agora crew, unlock unparalleled growth with innovative marketing solutions. We will help you the best with visionary thoughts, strategies, and designs. </p>
                </Col>
              </>)}
          </Row>
        </Container>
        <div className="black-wrapper content-wrapper">
          <Container>
            <ClientsPartnersItem items={clients_partners} />
          </Container>
        </div>
      </section>
      <section className="section5 container">
        <ContactForm text="Tell us a little bit about your project / company / concern. We’ll be in touch shortly." />
      </section>
    </>
  );
}

export default HomePage;