import React from 'react';
import './ProjectButton.css';
import { useNavigate } from 'react-router-dom';

function ProjectButton({ category, selectedCategory, onClick }) {
  const navigate = useNavigate();

  const handleClick = () => {
    // onClick(selectedCategory);

    let urlCategory = category.replace(/\//g, '').toLowerCase().replace(/\s+/g, '-');
    navigate(`/project/${urlCategory}`);
  };

  const isSelected = selectedCategory === category ? 'selected' : '';

  return (
    <span
      onClick={handleClick}
      className={`project-button ${isSelected}`}
    >
      {category}
    </span>
  );
}

export default ProjectButton;