import React from 'react';
import { Container, Row, Image } from 'react-bootstrap';

import './OurTeamPage.css';
import Team_Main from '../assets/team/Team_Main.png';
import Employee from '../components/Employee';
import employeesData from '../data/employees';


function OurTeamPage() {
  return (
    <>
      {window.innerWidth >= 768 ? (
        <div className="team_main_img_wrapper">
          <Image src={Team_Main} fluid />
        </div>
      ) : (<></>)}
      <Container>
        <section>
          <Row>
            <div>
              <h3>
                OUR TEAM,<br />
                COMPANY PRIDE
              </h3>
              <p>
                Our team comprises visionary thinkers, strategists, designers, each brining their own creative flair that synergies the power of innovation.
              </p>
            </div>
          </Row>
        </section>
      </Container>
      <section className="team-section2">
        <Row>
          <div>
            <p>
              “ Creativity is the untamed imagination by a focused vision. “
            </p>
            <p>
              Danny Hwang, CEO of AGORA Production
            </p>
          </div>
        </Row>
      </section>
      <Container>
        <section>
          <Row>
            <div className="team-section3">
              <div className="left-div">
                <h4>
                  Introducing
                </h4>
                <h5>
                  OUR TEAMMATES
                </h5>
              </div>
              <div className="right-div">
                <p>
                  2019<br />
                  Vancouver<br />
                  Canada<br />
                </p>
              </div>
            </div>
          </Row>
          {employeesData.map((employee, index) => (
            <Employee
              key={employee.id}
              name={employee.name}
              department={employee.department}
              position={employee.position}
              email={employee.email}
              phone={employee.phone}
              image={employee.image}
              isEven={index % 2 === 1}
            />
          ))}
        </section>
      </Container>
    </>
  );
}

export default OurTeamPage;