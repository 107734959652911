import React from 'react';
import './Service.css';

function Service({ title, content }) {
  return (
    <div className="service-container" style={{marginBottom: '3%'}}>
      <p className="service-title">{title}</p>
      <p>{content}</p>
    </div>
  );
}

export default Service;