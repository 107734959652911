import React from 'react';
import Arrow from '../assets/service/Arrow.svg';
import { Container, Row, Col, Image } from 'react-bootstrap';

import './ServiceStep.css';
import process from "../data/process"


function ServiceStep() {
    if (window.innerWidth > 1024) {
        // 화면 너비가 1024 이상인 경우
        return (
            <>
                {process.map((processItem, index) => (
                    <Container key={processItem.id} className="web-process-wrapper">
                        <Row>
                            {index % 2 === 0 ? (
                                // 짝수인 경우
                                <>
                                    <Col md={3} className="web-process-img-wrapper">
                                        <Image src={processItem.image} alt="process" fluid />
                                    </Col>
                                    <Col md={1}>
                                        <span className="process-number">
                                            {processItem.id}
                                        </span>
                                    </Col>
                                    <Col md={8} className="web-process-content-wrapper">
                                        <div>
                                            <p className="process-title">{processItem.title}</p>
                                        </div>
                                        <div>
                                            <ul className="list">
                                                {processItem.details.map((detail, detailIndex) => (
                                                    <li key={detailIndex}><p>{detail}</p></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Col>
                                </>
                            ) : (
                                // 홀수인 경우
                                <>
                                    <Col md={8} className="web-process-content-wrapper">
                                        <div>
                                            <p className="process-title">{processItem.title}</p>
                                        </div>
                                        <div>
                                            <ul className="list">
                                                {processItem.details.map((detail, detailIndex) => (
                                                    <li key={detailIndex}><p>{detail}</p></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </Col>
                                    <Col md={1}>
                                        <span className="process-number">
                                            {processItem.id}
                                        </span>
                                    </Col>
                                    <Col md={3} className="web-process-img-wrapper">
                                        <Image src={processItem.image} alt="process" fluid />
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Container>
                ))}
            </>
        );
    } else if (window.innerWidth >= 768) {
        // 화면 너비가 768 이상이지만 1024 미만인 경우
        return (
            <>
                {process.map((processItem) => (
                    <Col key={processItem.id} md={6} className="process-wrapper">
                        <p>{processItem.title}</p>
                    </Col>
                ))}
            </>
        );
    } else {
        // 화면 너비가 768 미만인 경우
        return (
            <>
                {process.map((processItem) => (
                    <div key={processItem.id} className="step">
                        <div>
                            <h6>{processItem.title}</h6>
                        </div>
                        <div className='arrow-wrapper'>
                            <img src={Arrow} alt="Arrow" />
                        </div>
                    </div>
                ))}

            </>
        );
    }
}
export default ServiceStep;
