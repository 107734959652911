import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import './Employee.css';

function Employee(props) {
  const isEmailTooLong = props.email.length > 23;

  return (
    <Row className={props.isEven ? 'even-row' : 'odd-row'}>
      {props.isEven ? (
        <>
          <Col xs={6} md={8} className="employee-wrapper even">
            <div>
              <h6>
                <span>{props.name}</span>
              </h6>
              <div>
                <div className="department-position-wrapper">
                  <p>{props.department}</p>
                  <p>{props.position}</p>
                </div>
                {isEmailTooLong ? (
                  <></>
                ) : (
                  <>
                    <div className="phone-email-wrapper">
                      <p>{props.email}</p>
                      <p>{props.phone}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col xs={6} md={4} className="employee-img-wrapper">
            <Image src={props.image} fluid/>
          </Col>
          {isEmailTooLong ? (
            <Row className="phone-email-wrapper">
              <p>{props.email}</p>
              <p>{props.phone}</p>
            </Row>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <Col xs={6} md={4} className="employee-img-wrapper">
            <Image src={props.image} fluid />
          </Col>
          <Col xs={6} md={8} className="employee-wrapper odd">
            <div>
              <h6>
                <span>{props.name}</span>
              </h6>
              <div>
                <div className="department-position-wrapper">
                  <p>{props.department}</p>
                  <p>{props.position}</p>
                </div>
                {isEmailTooLong ? (
                  <></>
                ) : (
                  <>
                    <div className="phone-email-wrapper">
                      <p>{props.email}</p>
                      <p>{props.phone}</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          {isEmailTooLong ? (
            <Row className="phone-email-wrapper">
              <p>{props.email}</p>
              <p>{props.phone}</p>
            </Row>
          ) : (
            <></>
          )}
        </>
      )}
    </Row>
  );
}

export default Employee;