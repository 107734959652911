import React from 'react';
import './LinkButton.css';

function LinkButton({ text, url }) {
  return (
    <div className="linkbutton-container mt-5">
      <div className="link-button">
        <a href={url}>
          {text}
        </a>
      </div>
    </div>
  );
}

export default LinkButton;