const services = [
    {
      id: 1,
      title: "Branding / Marketing",
      content: "Our expert team crafts compelling brand identities, devises innovative marketing campaigns, and delivers measurable results.",
    },
    {
        id: 2,
        title: "Google Business Set up",
        content: "By creating an engaging Google My Business profile to optimizing your online visibility with accurate information, we ensure your brand stands out in local searches.",
      },
      {
        id: 3,
        title: "Photo / Videography",
        content: "With an artistic eye and cutting-edge equipment, our team of skilled professionals will turn your vision into stunning visuals that leave a lasting impact and evoke emotions.",
      },
      {
        id: 4,
        title: "SEO Set up",
        content: "We meticulously analyze your website, conduct in-depth keyword research, and implement data-driven strategies to boost your search engine rankings.",
      },
      {
        id: 5,
        title: "Web / App Design",
        content: "Our expert designers craft visually stunning and user-friendly interfaces that elevate your digital presence and enhance the user experience.",
      },
      {
        id: 6,
        title: "News / Ads",
        content: "We deliver timely and reliable news updates across various industries, keeping you up-to-date with the latest developments and trends.",
      },
  ];
  
  export default services;