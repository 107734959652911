import React, { useEffect, useRef } from 'react';
import Main_Video from '../assets/home/Main_Video.mp4';


function Video() {
    const videoRef = useRef(null);

    useEffect(() => {
        const handleUserInteraction = () => {
            if (videoRef.current) {
                videoRef.current.play();
            }
            document.removeEventListener('click', handleUserInteraction);
        };
    
        document.addEventListener('click', handleUserInteraction);
    
        return () => {
            document.removeEventListener('click', handleUserInteraction);
        };
    }, []);
    

    return (
        <>
            <video ref={videoRef} style={{ pointerEvents: 'none' , width: '100%', maxWidth: '2000px'}} muted loop autoPlay playsInline webkit-playsinline="true">
                <source src={Main_Video} type="video/mp4" />
            </video>
        </>
    );
}

export default Video;