const subjects = [
    {
      id:1,
      title: "Design"
    },
    {
      id:2,
      title: "Marketing"
    },
    {
      id:3,
      title: "Branding"
    },
    {
      id:4,
      title: "Photo/Videography"
    },
    {
      id:5,
      title: "News/Ads"
    },
    {
      id:6 ,
      title: "SEO Set up"
    },
    {
      id:7,
      title: "Google Business Set up"
    },
  ];

  export default subjects;