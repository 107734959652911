import { Form } from 'react-bootstrap';

function FormField({ id, isRequired, label, type, required, placeholder, value, onChange ,readOnly = false}) {
    return (
        <Form.Group controlId={id}>
            <Form.Label className={isRequired ? "form-label-required" : ""}>{label}</Form.Label>
            <Form.Control
                type={type}
                required={required}
                placeholder={placeholder}
                autoComplete='off'
                value={value}
                onChange={(e) => onChange(e, id)}
                readOnly={readOnly}
                className={readOnly ? 'readonly-input' : ''}
            />
        </Form.Group>
    );
}

export default FormField;