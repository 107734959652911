import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import './Footer.css'

import Agora_Logo from '../assets/Agora_Logo_Footer.png';

function Footer() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [showScrollToTop, setShowScrollToTop] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
    };

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowScrollToTop(true);
        } else {
            setShowScrollToTop(false);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    
    return (
        <>
            {isMobile ? (
                <>
                {/* <footer className="fixed-bottom">
                    <Container>
                        <Row>
                            <Col>
                                <Link as={Link} className="footer-link nav-link" to="/our-team">Our Team</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link nav-link" to="/service">Service</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link nav-link" to="/project/photography">Project</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link nav-link" to="/contact-us">Contact Us</Link>
                            </Col>
                        </Row>
                    </Container>
                </footer> */}
                {showScrollToTop && (
                    <button className="scroll-to-top" onClick={scrollToTop} aria-label="Scroll to top">↑</button>
                )}
                </>
            ) : (
                <footer className="web-bottom">
                    <Container>
                        <div style={{margin:"22px 0px"}}>
                        <Row>
                            <Col>
                                <Image src={Agora_Logo} fluid />
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link" to="/our-team">Our Team</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link" to="/service">Service</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link" to="/project/photography">Project</Link>
                            </Col>
                            <Col>
                                <Link as={Link} className="footer-link" to="/contact-us">Contact Us</Link>
                            </Col>
                        </Row>
                        </div>
                        <hr className="divider" />
                        <Row className="web-bottom-second-content">
                            <Col className="copyright-wrapper">
                                <a href="https://www.agoraproduction.ca/" style={{textDecoration: 'none'}} target='_blank' rel="noopener noreferrer" aria-label="Visit Agora Production website">
                                    <p>©2024 by Agora production</p>
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            )}
        </>
    );
}

export default Footer;

