export const projects = [
  {
    id: 1,
    navName: "photography",
    category: "Photography",
    brandName: "Delhi 6 Indian bistro",
    beforeTitle: "Before they were...",
    before: [
      "Disconnected from trends",
      "Failed to engage with audience",
    ],
    afterTitle: "So we did...",
    after: [
      "Created online content strategy",
      "Curate a mix of visually appealing images & Videos",
      "Fell into an echo chamber"
    ],
    results: [
      "Increased brand loyalty and customer retention",
      "Enormous growth in ordering cocktails & catering service"
    ],
    story: "Prior to commencing our role as a social marketer for Delhi 6 Indian bistro, their digital presence was inadvertently disconnected from the latest trends and failed to engage with the restaurant's target audience effectively. It had fallen into an echo chamber, recycling similar types of posts without adapting to the dynamic social media landscape.\n When we embarked on the task of managing their social media presence, we wanted to create a content strategy that aligns with the defined objectives and resonates with the target audience. Curate a mix of visually appealing images and engaging videos that highlight the premium cocktails and unique ambiance, and needless to say, their authentic indian quisine. \n As a result, our data-driven approach has allowed us to continuously refine our strategies that optimize reach, engagement, and return on investment for the restaurant. The insights gained from social media analytics have steered our campaigns, ensuring that our content resonates with our target audience, resulting in increased brand loyalty and customer retention.",
    growthOfFollowers: '82.46%',
  },
  {
    id: 2,
    navName: "videography",
    category: "Videography",
    brandName: "Bar None Nightclub",
    beforeTitle: "Before, they were...",
    before: [
      "The views on the Reels I uploaded to Instagram used to be between 500 and 1500",
      "The likes were very few, usually around 10",
    ],
    afterTitle: "So we did...",
    after: [
      "Visited club to shoot videos and photos, created Reels and Instagram posts, and regularly uploaded them to Instagram",
      "Reduced fixed costs through platform integration",
      "Configured email notifications to enhance the quality of customer service",
      "Set up the website for efficient management"
    ],
    results: [
      "The views on my Instagram Reels are now consistently over 6000, often reaching over 10000",
      "After managing the club, there has been an increase in guest list registrations and VIP table purchases",
      "My followers have been growing by over 200 each month",
      "The number of likes has increased to between 100 and 150",
      "The number of visitors to the website has increased",
      "Event companies are requesting videos and photos more frequently"
    ],
    story: "We took charge of creating and uploading captivating Instagram Reels and posts for Bar None Nightclub. By regularly showcasing the club's vibrant atmosphere through high-quality content, we’ve seen impressive results. Our posts consistently garner over 6000 views, often surpassing 10000, with likes ranging between 80 and 150. The nightclub’s follower count has been growing by over 200 each month, expanding their online community.\nOur comprehensive approach goes beyond social media. By integrating platforms and optimizing the club’s website, we have achieved higher website traffic, leading to increased awareness and interest. Setting up efficient email notifications has enhanced the quality of customer interactions and service.\n\nThe impact of our digital strategy is evident in the real world. More guests are registering on the guest list and booking VIP tables, directly contributing to the club’s bustling atmosphere and success. Additionally, there has been a notable increase in requests for videos and photos from event companies, further solidifying Bar None Nightclub’s reputation.",
    growthOfFollowers: "",
    additional: [
      "Horizontal Video", 
      "Vertical Video"
    ]
  },
  {
    id: 3,
    navName: "branding",
    category: "Branding",
    brandName: "SOODA",
    beforeTitle: "Before they were...",
    before: [
      "Needed to carve a unique identity that resonated with the younger generation.",
      "Had difficulties to advertise new food or signature food by simply re-posting photos posted by customers.",
    ],
    afterTitle: "So we did...",
    after: [
      "To capture the essence of a young vibe, we have embraced neon colors as the restaurant's theme",
      "The trendy photography for signature menu also help them build their brand image."
    ],
    results: [
      "Social media influencers and young food enthusiasts flocked to Sooda due to young branding.",
      "Since our advertisement was introduced, the number of customers visiting us after looking at Instagram has increased."
    ],
    story: "Upon understanding Sooda's unique culinary vision and target audience, our team recognized the need to carve a unique identity that resonated with the younger generation. Driven by a passion to be perceived as trendsetters in the culinary world, we set out on a creative journey to establish \"Sooda\" as the ultimate destination for a young and vibrant dining experience.\n\nTo capture the essence of a young vibe, we have embraced neon colors as the restaurant's theme. Neon hues of electric blue, vibrant pink, and luminescent green adorned the walls, infusing the space with a captivating energy.\n\nThe trendy photography for signature menu also help them build their brand image. As a result, social media influencers and young food enthusiasts flocked to \"Sooda\" to experience its immersive ambiance and the creative brilliance of its menu.",
    growthOfFollowers: "171.65%",
  },
  {
    id: 4,
    navName: "news-ads",
    category: "News / Ads",
    brandName: "Agora Daily",
    beforeTitle: "Our team recognized...",
    before: [
      "he need to bridge the language barrier and ensure that diverse immigrant communities could stay informed about important news in their native languages.",
    ],
    afterTitle: "So we did...",
    after: [
      "Set out on a mission to become a reliable source of highlighted and pressing news delivered conveniently to those who faced language barriers.",
      "Assembled a diverse team of multilingual professionals who shared a passion for empowering communities through information."
    ],
    results: [
      "Became a go-to resource for those seeking timely and relevant news updates.",
      "Became an integral part of the daily lives of immigrants across Canada."
    ],
    story: "In the multicultural landscape of Canada, the Agora Company recognized the need to bridge the language barrier and ensure that diverse immigrant communities could stay informed about important news in their native languages. With a deep commitment to inclusivity and accessibility, Agora set out on a mission to become a reliable source of highlighted and pressing news delivered conveniently to those who faced language barriers.\n\nUnderstanding the challenges faced by immigrants in keeping up-to-date with local news, Agora assembled a diverse team of multilingual professionals who shared a passion for empowering communities through information. With a focus on essential news topics, they diligently curated concise and relevant summaries to keep their audience well-informed without overwhelming them with excessive information.\n\nIn a world filled with an overwhelming amount of information, Agora took on the responsibility of sifting through news sources and curating only the most crucial and pressing headlines. By focusing on topics that directly impacted their audience's lives, Agora became a go-to resource for those seeking timely and relevant news updates.\n\nWith our dedication to providing highlighted and essential news updates, Agora became an integral part of the daily lives of immigrants across Canada. Our platform not only informs but also empowers and connected communities.",
    growthOfFollowers: "",
  },
  {
    id: 5,
    navName: "google-business",
    category: "Google Business Set Up",
    brandName: "West Pacific Service LTD",
    beforeTitle: "Our team recognized...",
    before: [
      "The construction company, West Pacific Service LTD, found itself trapped in a digital conundrum for years.",
      "The elusive Google account lockout left them without access to essential online tools, hindering their ability to showcase their expertise and services to potential clients."
    ],
    afterTitle: "So we did...",
    after: [
      "Managed to succeed in restoring the construction company's Google My Business access in a week."
    ],
    results: [
      "The collaboration had not only resolved the long-standing issue but also fostered a lasting partnership built on trust and shared determination.",
      "Now our partener, West Pacific Service  finally gained the key to unlock the full potential of their digital presence."
    ],
    story: "For years, the construction company found itself trapped in a digital conundrum. Despite their best efforts, they were unable to verify their business on Google My Business. The elusive Google account lockout left them without access to essential online tools, hindering their ability to showcase their expertise and services to potential clients.\n\nDespite countless attempts to regain access, the construction company's efforts seemed to lead nowhere. Google's verification process remained a roadblock, and they were left with a sinking feeling of helplessness as the years went by.\n\nHope was reignited when they stumbled upon our team Agora. We managed to succeed in restoring the construction company's Google My Business access. Now our partener, West Pacific Service  finally gained the key to unlock the full potential of their digital presence.\n\nThe collaboration had not only resolved the long-standing issue but also fostered a lasting partnership built on trust and shared determination.",
    growthOfFollowers: "",
  }
];

