import React, { useState, useEffect } from 'react';
import './Project.css';
import { Container, Row } from 'react-bootstrap';
import ProjectButton from './ProjectButton';
import { projects } from '../data/projects';
import CustomAccordion from './CustomAccordion';

function Project({ category }) {
  const [selectedCategory, setSelectedCategory] = useState('Photography');

  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
    }
  }, [category]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects = selectedCategory === 'photography' ? projects : projects.filter(project => project.category === selectedCategory);

  return (
    <>
      <div className="project-container">
        <Container>
          <Row>
            <div className="p-3">
              <div className="h4-wrapper">
                <h4>What we have done</h4>
              </div>
              <h5>Projects</h5>
            </div>
          </Row>
          <Row>
            <div className="project-btn-container">
              <div className="project-btn-wrapper" style={{marginBottom: '3%'}}>
                <ProjectButton
                  onClick={() => handleCategoryChange('Photography')}
                  category="Photography"
                  selectedCategory={selectedCategory}
                >
                  Photography
                </ProjectButton>
                <ProjectButton
                  onClick={() => handleCategoryChange('Videography')}
                  category="Videography"
                  selectedCategory={selectedCategory}
                >
                  Videography
                </ProjectButton>
                <ProjectButton
                  onClick={() => handleCategoryChange('Branding')}
                  category="Branding"
                  selectedCategory={selectedCategory}
                >
                  Branding
                </ProjectButton>
                <ProjectButton
                  onClick={() => handleCategoryChange('News / Ads')}
                  category="News / Ads"
                  selectedCategory={selectedCategory}
                >
                  News / Ads
                </ProjectButton>
                <ProjectButton
                  onClick={() => handleCategoryChange('Google Business Set Up')}
                  category="Google Business Set Up"
                  selectedCategory={selectedCategory}
                >
                  Google Business Set Up
                </ProjectButton>
              </div>
            </div>
          </Row>
        </Container>

        <div className="project-content">
          {filteredProjects.map(project => (
            <div key={project.id}>
              <div className="black-wrapper">
                <Container>
                  <div className="project-title-wrapper">
                    <h6>{project.brandName}</h6>
                    <p style={{ fontWeight: "bold" }}>{project.beforeTitle}</p>
                    <ul className="list">
                      {project.before.map((item, index) => (
                        <li key={index}><span>{item}</span></li>
                      ))}
                    </ul>
                    <hr />
                    <p style={{ fontWeight: "bold" }}>{project.afterTitle}</p>
                    <ul className="list">
                      {project.after.map((item, index) => (
                        <li key={index}><span>{item}</span></li>
                      ))}
                    </ul>
                  </div>
                </Container>
              </div>
                <Container>
                  <h6>And this happened</h6>
                  <ul className="list">
                    {project.results.map((item, index) => (
                      <li key={index}><span>{item}</span></li>
                    ))}
                  </ul>
                  <h6>Want to see full story?</h6>
                  <div className="story">
                    {project.story.split('\n').map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                  </div>
                  </Container>
              <div className="project-content-wrapper">
                {project.growthOfFollowers && (
                  <div className="growth-wrapper">
                    <h3>{project.growthOfFollowers}</h3>
                    <p>growth of followers</p>
                  </div>
                )}
              </div>
              {project.additional ? (
                <>
                  <div className="black-wrapper project-title-wrapper" style={{marginTop: '3rem'}}>
                    <Container>
                      <h6 style={{marginTop: '1rem'}}>See More Video Projects</h6>
                    </Container>
                  </div>
                  <div>
                    <Container>
                      <CustomAccordion additional={project.additional} />
                    </Container>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Project;
