const process = [
    {
        id: 1,
        title: "Discovery and Strategy",
        image: require("../assets/service/1_Discovery.png"),
        details: [
            "The manager leads the initial phase, understanding the client's requirements, goals, and target audience.",
            "The marketer conducts market research and competitor analysis to identify opportunities and challenges.",
            "The team collaborates to develop a comprehensive marketing strategy, including branding, content, and promotion plans.",
        ]
    },
    {
        id: 2,
        title: "Design and Content Creation",
        image: require("../assets/service/2_Design.png"),
        details: [
            "Designers work on creating visually appealing and on-brand assets, including graphics, logos, and website layouts.",
            "Photographers/ Videographers capture high-quality images and videos that align with the brand's messaging and vision.",
            "TContent creators craft engaging and compelling copy for websites, social media, blog posts, and other marketing materials.",
        ]
    },
    {
        id: 3,
        title: "Development and Implementation",
        image: require("../assets/service/3_Development.png"),
        details: [
            "Developers take the approved designs and turn them into functional websites, applications.",
            "The team ensures that the website is responsive, user-friendly, and optimized for search engines (SEO).",
            "Managers oversee the project timeline, ensuring smooth communication between team members and clients.",
        ]
    },
    {
        id: 4,
        title: "Advertising and Promotion\nSocial Media and Content Distribution",
        image: require("../assets/service/4_Advertising.png"),
        details: [
            "Marketers execute targeted advertising campaigns across various platforms, such as social media, search engines.",
            "The team monitors campaign performance, making data-driven decisions to optimize ad spend and improve results.",
            "Photographers/videographers contribute visual content, and the team ensures consistency in branding and messaging.",
        ]
    },
    {
        id: 5,
        title: "Analytics and Reporting",
        image: require("../assets/service/5_Analytics.png"),
        details: [
            "Managers and marketers analyze data from various marketing channels to measure the effectiveness of campaigns.",
            "The team prepares comprehensive reports, highlighting key performance indicators (KPIs) and providing insights for future improvements.",
        ]
    },
];

export default process;