import v1 from '../assets/project/1.mov';
import v2 from '../assets/project/2.mov';
import v3 from '../assets/project/3.mov';
import v4 from '../assets/project/4.mov';
import v5 from '../assets/project/5.mov';
import v6 from '../assets/project/6.mov';
import v7 from '../assets/project/7.mov';
import v8 from '../assets/project/8.mov';
import v9 from '../assets/project/9.mov';
import v10 from '../assets/project/10.mov';

export const videoprojects = [
    {
        id: 1,
        category: "Horizontal Video",
        title: "Construction Drone",
        link: "https://www.youtube.com/embed/YHaDVKzTAOc?si=wyWBqvqZEgz5e1Un",
        author: "Gyu",
    },
    {
        id:2,
        category: "Horizontal Video",
        title: "Piano MV",
        link: "https://www.youtube.com/embed/t90vHf7boR0?si=R444N2berl3CxZWo",
        author: "Danny"
    },
    {
        id: 3,
        category: "Horizontal Video",
        title: "Exhibition film",
        link: "https://player.vimeo.com/video/881107615?h=20f1f1f0c4",
        author: "Jusung"
    },
    {
        id: 4,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v1,
        author: "Agora"
    },
    {
        id: 5,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v2,
        author: "Agora"
    },
    {
        id: 6,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v3,
        author: "Agora"
    },
    {
        id: 7,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v4,
        author: "Agora"
    },
    {
        id: 8,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v5,
        author: "Agora"
    },
    {
        id: 9,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v6,
        author: "Agora"
    },
    {
        id: 10,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v7,
        author: "Agora"
    },
    {
        id: 11,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v8,
        author: "Agora"
    },
    {
        id: 12,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v9,
        author: "Agora"
    },
    {
        id: 13,
        category: "Vertical Video",
        title: "Exhibition film",
        link: v10,
        author: "Agora"
    }
]