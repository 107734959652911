import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Layout from './layouts/Layout'
import HomePage from './pages/HomePage';
import OurTeamPage from './pages/OurTeamPage';
import ServicePage from './pages/ServicePage';
import ProjectPage from './pages/ProjectPage';
import Project from './components/Project';
import ContactUsPage from './pages/ContactUsPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage/>} />
          <Route path="/our-team" element={<OurTeamPage/>}/>
          <Route path="/service" element={<ServicePage/>}/>
          <Route path="/project" element={<ProjectPage />}>
            <Route path=":category" element={<Project />} />
          </Route>
          <Route path="/contact-us" element={<ContactUsPage/>}/>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
