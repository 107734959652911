import FormField from './FormField';
import { Col, Row } from 'react-bootstrap';

function RenderFormField(id, label, type, placeholder, value, handleChange, isTextarea, isContactUs, readOnly = false) {
    return (
        <Col xs={12} md={isContactUs ? 6 : 12}>
            <Row>
                {isTextarea ? (
                    <FormField
                        id={id}
                        isRequired={true}
                        label={label}
                        type={type}
                        required={true}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => handleChange(e, id)}
                        readOnly={readOnly}
                    />
                ) : (
                    <FormField
                        id={id}
                        isRequired={true}
                        label={label}
                        type={type}
                        required={true}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e) => handleChange(e, id)}
                        readOnly={readOnly}
                    />
                )}
            </Row>
        </Col>
    );
}

export default RenderFormField;
