const employees = [
    {
      id: 1,
      name: "Danny Hwang",
      department: "CEO",
      position: "Founder",
      email: "info@agorafield.com",
      phone: "+1 7783235976",
      image: require("../assets/team/Danny.png"),
    },
    // {
    //   id: 2,
    //   name: "Gyu Lee",
    //   department: "Manager",
    //   position: "Drone / Photographer",
    //   email: "supervisor@agoraproduction.ca",
    //   phone: "+1 7788148530",
    //   image: require("../assets/team/Gyu.png"),
    // },
        {
      id: 2,
      name: "Iseul Seo",
      department: "Manager",
      position: "Drone / Photographer",
      email: "supervisor@agoraproduction.ca",
      phone: "+1 7796363329",
      image: require("../assets/team/Iseul.jpg"),
    },
    {
      id: 3,
      name: "Sulgi Kim",
      department: "Design",
      position: "UX / UI Designer",
      email: "sulgikim@agoraproduction.ca",
      phone: "+1 7786364325",
      image: require("../assets/team/Sulgi.webp"),
    },

    {
      id: 4,
      name: "Hendrik Kim",
      department: "IT",
      position: "Senior Developer",
      email: "cto@agoraproduction.ca",
      phone: "+1 6729993639",
      image: require("../assets/team/Hendrick.png"),
    },
    // {
    //   id: 5,
    //   name: "Jen Kwon",
    //   department: "IT",
    //   position: "Junior Developer",
    //   email: "it@agoraproduction.ca",
    //   phone: "+1 6726735364",
    //   image: require("../assets/team/Jen.png"),
    // },
        {
      id: 6,
      name: "Jane Lee",
      department: "Marketing ",
      position: "Marketing Manager",
      email: "manager@agoraproduction.ca",
      phone: "+1 7789870131",
      image: require("../assets/team/Jane.png"),
    },
    {
      id: 7,
      name: "Loui Choi",
      department: "Sales",
      position: "Sales coordinator",
      email: "loui@agoraproduction.ca",
      phone: "+1 6043566883",
      image: require("../assets/team/Loui.jpg"),
    },
    {
      id: 8,
      name: "David Choi",
      department: "Advertisement",
      position: "Contents Creator",
      email: "ads@leteatgo.ca",
      phone: "+1 7789557365",
      image: require("../assets/team/David.jpg"),
    },
    {
      id: 9,
      name: "Denzil Lee",
      department: "Advertisement",
      position: "Marketing Assistant",
      email: "leteatgo.denzil@gmail.com",
      phone: "+1 2368653159",
      image: require("../assets/team/Denzil.png"),
    },
    {
      id: 10,
      name: "Jusung Hyung",
      department: "Advertisement",
      position: "Videographer",
      email: "",
      phone: "",
      image: require("../assets/team/Jusung.png"),
    },
    {
      id: 11,
      name: "Chloe Kwon",
      department: "Spain Branch",
      position: "Director",
      email: "gm.spain@agoraproduction.ca",
      phone: "+34 679904431",
      image: require("../assets/team/Chloe.png"),
    },

      // {
    //   id: 11,
    //   name: "Noah Yun",
    //   department: "UX UI Designer",
    //   position: "Product Designer",
    //   email: "designer@agoraproduction.ca",
    //   phone: "+82 1094840870",
    //   image: require("../assets/team/Noah.png"),
    // },
  ];
  
  export default employees;