import { useState } from 'react';
import { Container, Row, Col, Image, Form, Button } from 'react-bootstrap';
import './ContactForm.css';

import RenderFormField from './RenderFormField';
// import DropdownFormField from './DropdownFormField';
import SubjectButton from './SubjectButton';

import subjects from '../data/subjects';
import Contact_Us from '../assets/home/Contact_Us.png';
import emailjs from '@emailjs/browser';

function ContactForm({ text, bottom_text, isTextarea, isContactUs }) {

  //  To use the DropdownFormField component, options should be passed as a prop.
  // const options = ["Design", "Marketing", "Branding", "Photo / Videography", "News / Ads", "SEO Set up", "Google Business Set up"];

  // const handleDropdownChange = (selectedOption) => {
  //   console.log("Selected Option:", selectedOption);
  // };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleChange = (e, id) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const handleSelect = (subject) => {
    setSelectedSubjects((prevSelectedSubjects) => {
      if (prevSelectedSubjects.includes(subject)) {
        return prevSelectedSubjects.filter(item => item !== subject);
      } else {
        return [...prevSelectedSubjects, subject];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const templateParams = {
      from_name: formData.name,
      from_email: formData.email,
      phone: formData.phone,
      subject: selectedSubjects.join(', '),
      message: formData.message
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
      .then((response) => {
        alert('Success:', response);
        // Clear form data after successful submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
        setSelectedSubjects([]);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <>
      {window.innerWidth >= 768 ? (
          <>
            <div className="contact-form-container">
              <Row className="contact-form-wrapper" style={{marginRight: '0', marginLeft:'0'}}>
                <Col md={!isContactUs ? 8 : 12} xs={12} style={{paddingLeft: '3%'}}>
                  <Form className="contact-form" onSubmit={handleSubmit}>
                    <h4>
                      Get in touch with
                    </h4>
                    <h5>
                      AGORA PRODUCTION
                    </h5>
                    <p>{text}</p>
                    <div>
                      {isContactUs && (
                        <>
                        <Row>
                          <div className="subject-btn-container">
                            <div className="subject-btn-wrapper">
                              <SubjectButton
                                subjects={subjects}
                                selectedSubjects={selectedSubjects}
                                onSelect={handleSelect}
                              />
                            </div>
                          </div>
                        </Row>
                        <Row>
                          <Col md={6}>
                            <p>{bottom_text}</p>
                          </Col>
                          <Col md={6} className="contact-form-hr-wrapper">
                            <hr/>
                          </Col>
                        </Row>
                        </>
                        
                      )}
                    </div>
                    <div>
                      <Row>
                        {RenderFormField("name", "Name", "text", "Please write down your full name", formData.name, handleChange, false, isContactUs)}
                        {RenderFormField("email", "Email", "email", "Please write down your email", formData.email, handleChange, false, isContactUs)}
                        {RenderFormField("phone", "Phone", "text", "Please write down your phone number", formData.phone, handleChange, false, isContactUs)}
                        {isContactUs && (
                          <>
                            {RenderFormField("subject", "Subject", "text", "Please select the subject", selectedSubjects.join(', '), handleChange, false, true)}
                          </>
                        )}
                        {RenderFormField("message", "Please add details or relevant questions", isTextarea ? "textarea" : "textarea", "Please write down your goals, challenges, expectations.. etc", formData.message, handleChange, isTextarea)}
                        {!isContactUs && (
                        <Col md={12}>
                          <div className="m-2 " style={{ textAlign: 'center', whiteSpace: 'pre-line' }}><p>{bottom_text}</p></div>
                        </Col>
                        )}
                        <div className="button-container">
                          <Button type="submit">
                            {isContactUs ? (
                              <>Submit</>
                            ) : (
                              <>Get Free Consultation</>
                            )}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </Form>
                </Col>
                {!isContactUs && (
                <Col xs={5} md={4} style={{paddingRight: "0"}}>
                  <Image src={Contact_Us} fluid />
                </Col>
                )}
              </Row>
            </div>
          </>) : (
          <>
            <Container className="contact-form-container">
              <div className="contact-form-wrapper">
                <Col xs={12}>
                  <Form className="contact-form" onSubmit={handleSubmit}>
                    <h4>
                      Get in touch with
                    </h4>
                    <h5>
                      AGORA PRODUCTION
                    </h5>
                    <p>{text}</p>
                    <div>
                      {isContactUs && (
                        <Row>
                          <div className="subject-btn-container">
                            <div className="subject-btn-wrapper">
                              <SubjectButton
                                subjects={subjects}
                                selectedSubjects={selectedSubjects}
                                onSelect={handleSelect}
                              />

                            </div>
                          </div>
                        </Row>
                      )}
                    </div>
                    <div>
                      <Row>
                        {RenderFormField("name", "Name", "text", "Please write down your full name", formData.name, handleChange)}
                        {RenderFormField("email", "Email", "email", "Please write down your email", formData.email, handleChange)}
                        {RenderFormField("phone", "Phone", "text", "Please write down your phone number", formData.phone, handleChange)}
                        {isContactUs && (
                          <>
                          {RenderFormField("subject", "Subject", "text", "Please select the subject", selectedSubjects.join(', '), handleChange, false, true)}
                          </>
                        )}
                        {RenderFormField("message", "Please add details or relevant questions", isTextarea ? "textarea" : "textarea", "Please write down your goals, challenges, expectations.. etc", formData.message, handleChange, isTextarea)}
                        <Col md={12}>
                          <div className="m-2 " style={{ textAlign: 'center', whiteSpace: 'pre-line' }}><p>{bottom_text}</p></div>
                        </Col>
                        <div className="button-container">
                          <Button type="submit" className="submit-button">
                            {isContactUs ? (
                              'Submit'
                            ) : (
                              'Get Free Consultation'
                            )}
                          </Button>
                        </div>
                      </Row>
                    </div>
                  </Form>
                </Col>
              </div>
            </Container>
          </>
        )
      }
    </>
  );
}

export default ContactForm;
