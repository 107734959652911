import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import './FlowText.css';


function FlowText({ texts }) {
    const [animate, setAnimate] = useState(true);
    const onStop = () => setAnimate(false);
    const onRun = () => setAnimate(true);


    // return (
    //     <>
    //         <Container>
    //             <Row style={{marginBottom: "0px"}}>
    //                 <div className="flow-container">
    //                     <div className="flow-text">
    //                         {texts.map((text, index) => (
    //                             <Col key={index} className="flow-wrap item"><p>{text}</p></Col>
    //                         ))}
    //                     </div>
    //                 </div>
    //             </Row>
    //         </Container>
    //     </>
    // );

    return (
        <div className="wrapper">
            <div className="slide_container">
                <ul
                    className="slide_wrapper"
                    onMouseEnter={onStop}
                    onMouseLeave={onRun}
                >
                    <div
                        className={"slide original".concat(
                            animate ? "" : " stop"
                        )}
                    >
                        {texts.map((text, index) => (
                            <li
                                key={text}
                                className={index % 2 === 0 ? "big" : "small"}
                            >
                                <Col key={index} className="flow-wrap item"><p>{text}</p></Col>
                            </li>
                        ))}
                    </div>
                    <div
                        className={"slide clone".concat(animate ? "" : " stop")}
                    >
                        {texts.map((text, index) => (
                            <li
                                key={text}
                                className={index % 2 === 0 ? "big" : "small"}
                            >
                                <Col key={index} className="flow-wrap item"><p>{text}</p></Col>
                            </li>
                        ))}
                    </div>
                </ul>
            </div>
        </div>
    );


}

export default FlowText;