import React, { useEffect, useState } from 'react';
import './Header.css';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import Agora_Logo from '../assets/Agora_Logo.png';

import { Link } from 'react-router-dom'; // react-router-dom의 Link를 import

function Header() {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize(); // 초기 실행

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isMobile ? (
                <>
                    <Navbar key='sm' expand='sm'>
                        <Container fluid className="grid-container">
                            <Col xs={12} md={2}>
                                <Navbar.Brand href="/">
                                    <Image src={Agora_Logo} fluid />
                                </Navbar.Brand>
                            </Col>
                        </Container>
                    </Navbar>
                    {/* bg-body-tertiary */}
                    <Container className=" sticky-sm-top">
                    <Row >
                        <Col>
                            <Link as={Link} className="nav-link mobile-nav-link" to="/our-team">Our Team</Link>
                        </Col>
                        <Col>
                            <Link as={Link} className="nav-link mobile-nav-link" to="/service">Service</Link>
                        </Col>
                        <Col>
                            <Link as={Link} className="nav-link mobile-nav-link" to="/project/photography">Project</Link>
                        </Col>
                        <Col>
                            <Link as={Link} className="nav-link mobile-nav-link" to="/contact-us">Contact Us</Link>
                        </Col>
                    </Row>
                    </Container>
                </>

            ) : (
                <Navbar expand='lg'>
                    <Container fluid className="grid-container">
                        <Link as={Link} className="nav-link" to="/our-team">Our Team</Link>
                        <Link as={Link} className="nav-link" to="/service">Service</Link>
                        <Navbar.Brand href="/">
                            <Image src={Agora_Logo} fluid />
                        </Navbar.Brand>
                        <NavDropdown
                            title="Project"
                            id={`offcanvasNavbarDropdown-expand-lg`}
                            className="hover-dropdown"
                        >
                            <NavDropdown.Item as={Link} to="/project/photography" >
                                Photography
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/project/videography">
                                Videography
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/project/branding">
                                Branding
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/project/news-ads" >
                                News / Ads
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/project/google-business-set-up">
                                Google Business
                            </NavDropdown.Item>
                        </NavDropdown>
                        <Link as={Link} className="nav-link" to="/contact-us">Contact Us</Link>
                    </Container>
                </Navbar>
            )}
        </>
    );
}

export default Header;