import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import ClientsPartnersCarousels from './ClientsPartnersCarousels';

// imageSrc, quote, author, role 
const ClientsPartnersItem = ({ items }) => {
    const [isWideScreen, setIsWideScreen] = useState(window.innerWidth >= 1280);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth >= 1025);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            {isWideScreen ? (

                // <div className="gray-wrapper">
                //     <div>
                //         <div className="circle">
                //             <Image src={imageSrc} fluid />
                //         </div>
                //     </div>
                //     <div>
                //         <p>
                //             {quote}
                //             <br /><br />
                //             {author},
                //             <br />
                //             <span className="clients-partners-title">{role}</span>
                //         </p>
                //     </div>
                // </div>
                <>
                    {items.map(({ imageSrc, quote, author, role }, index) => (
                        <div key={index} className="gray-wrapper">
                        <div className="clients-partners-item">
                            <div className="circle">
                                <Image src={imageSrc} fluid />
                            </div>
                            <div>
                                <p>
                                    {quote}
                                    <br /><br />
                                    {author},
                                    <br />
                                    <span className="clients-partners-title">{role}</span>
                                </p>
                            </div>
                        </div>
                        </div>
                    ))}
                </>
            ) : (
                <>
                  <ClientsPartnersCarousels items={items}/>
                </>
              
            )}
        </>
    );
}

export default ClientsPartnersItem;