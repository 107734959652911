import React from 'react';
import { Col } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import './ServiceItem.css';

function ServiceItem({ imageSrc, title, description }) {
    return (
        <Col className="service-item-wrapper">
            <div className="service-item-img-wrapper">
                <Image src={imageSrc} fluid />
            </div>
            <div>
                <p className="service-item-name">{title}</p>
                {window.innerWidth >= 768 && (
                    <>
                        <hr />
                        <p style={{ color: '#8A8A8A' }}>{description}</p>
                    </>
                )}
            </div>
        </Col>
    );
}

export default ServiceItem;