import React from 'react';
import { useParams } from 'react-router-dom';

import Project from '../components/Project';
import LinkButton from '../components/LinkButton';
function ProjectPage() {
  const { category } = useParams();

  const categoryMappings = {
    'photography': 'Photography',
    'videography': 'Videography',
    'branding': 'Branding',
    'news-ads': 'News / Ads',
    'google-business-set-up': 'Google Business Set Up',
  };

  const updatedCategory = categoryMappings[category] || category;

  return (
    <>
        <Project category={updatedCategory} />
      <div className='mb-5'>
        <LinkButton text="Contact Us" url="/contact-us" />
      </div>
    </>
  );
}

export default ProjectPage;